// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-citrus-js": () => import("./../src/pages/citrus.js" /* webpackChunkName: "component---src-pages-citrus-js" */),
  "component---src-pages-elements-js": () => import("./../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("./../src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-mighties-js": () => import("./../src/pages/mighties.js" /* webpackChunkName: "component---src-pages-mighties-js" */),
  "component---src-pages-pomegranates-js": () => import("./../src/pages/pomegranates.js" /* webpackChunkName: "component---src-pages-pomegranates-js" */),
  "component---src-pages-table-grapes-js": () => import("./../src/pages/table-grapes.js" /* webpackChunkName: "component---src-pages-table-grapes-js" */),
  "component---src-pages-tree-fruit-js": () => import("./../src/pages/tree-fruit.js" /* webpackChunkName: "component---src-pages-tree-fruit-js" */)
}

